<template>
  <div class="app-container">
       <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum"
                 :loading="loading"
                 :total="total">
        <template v-slot:table>
             <el-table-column align="center" label="头像">
                <template slot-scope="scope">
                    <img :src="scope.row.avatarUrl?scope.row.avatarUrl : '../../../assets/images/avatarDefault.png'" class="avatar"/>
                </template>
            </el-table-column>
            <el-table-column prop="nickeName" align="center" label="昵称">
            </el-table-column>
            <el-table-column prop="integral" align="center" label="积分">
            </el-table-column>
            <el-table-column prop="createTime" align="center" label="积分时间">
            </el-table-column>
            <el-table-column prop="handle" align="center" label="标识">
            </el-table-column>
            <el-table-column prop="phone" align="center" label="电话">
            </el-table-column>
        </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchPageVipRecord } from '@/api/customPage'
export default {
  components: {
      commonTable
  },
  data() {
    return {
      pageId: null,
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    }
  },
  components: {
    commonTable,
  },
  mounted() {
    this.pageId = this.$route.query.pageId
    let params = {
        customPageId: this.pageId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
    }
    fetchPageVipRecord(params).then(res => {
        if(res.data && res.data.list) {
            this.tableData = res.data.list
            this.total = res.data.total
        }
    })
  }
}
</script>
<style scoped>
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
